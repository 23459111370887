import {
  BoatType,
  ManufacturerType,
  SearchResultItem,
} from '@components/search/SearchResultItem/SearchResultItem.component';
import { FC, useMemo } from 'react';
import { useHits } from 'react-instantsearch';
import styles from './CoverSearchbox.module.scss';

interface HitsPanelProps {
  showOnEmptyQuery?: boolean;
}

export const HitsPanel: FC<HitsPanelProps> = ({ showOnEmptyQuery = false }) => {
  const { items, results } = useHits<BoatType | ManufacturerType>();
  const shouldRender = useMemo(() => {
    if (showOnEmptyQuery) {
      return true;
    }
    if (results.query.length) {
      return true;
    }
  }, [results.query.length, showOnEmptyQuery]);

  if (shouldRender) {
    return (
      <div className={styles.hitsList}>
        {items.map((hit) => (
          <SearchResultItem indexName={results.index} item={hit} variant="list" key={hit.__queryID} />
        ))}
      </div>
    );
  }
};
