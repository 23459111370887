import { HeartFilled } from '@ant-design/icons';
import { EyeOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { Highlight } from 'react-instantsearch';
import styles from './SearchResultItem.module.scss';

type ResultItemImage = {
  name: string;
  alternativeText: string;
  url?: string;
};

export type BoatType = {
  image?: ResultItemImage;
  model: string;
  slug: string;
  year_first: number;
  favorites_count?: number;
  views_count?: number;
  manufacturer: {
    name: string;
    slug: string;
  };
};

export type ManufacturerType = {
  id: number;
  name: string;
  slug: string;
  publicBoatsCount: number;
  description: string;
  logo?: ResultItemImage;
};

export interface SearchResultItemProps {
  variant: 'card' | 'list';
  indexName: string;
  item: BoatType | ManufacturerType;
}

const { Title } = Typography;

const SearchResultItem: FC<SearchResultItemProps> = ({ indexName, item }) => {
  if (indexName === 'boat') {
    const itemBoat = item as BoatType;
    if (!itemBoat.model || !itemBoat.year_first || !itemBoat.manufacturer) {
      return null;
    }

    return (
      <div className={styles.searchResultItemWrapper}>
        <Link
          href={`/boat/${itemBoat.slug}`}
          title={`${itemBoat.year_first} ${itemBoat.manufacturer.name} ${itemBoat.model}`}
        >
          <div className={styles.listItemWrapper}>
            <div className={styles.listItemImage}>
              {!!itemBoat?.image?.url ? (
                <Image
                  alt={itemBoat.image.alternativeText || itemBoat.model}
                  src={itemBoat.image.url}
                  width={115}
                  height={65}
                />
              ) : (
                <div className={styles.noListItemImagePlaceholder}>
                  <span>No image</span>
                </div>
              )}
            </div>
            <div className={styles.listItemText}>
              <Title level={3}>
                <Highlight attribute="year_first" hit={itemBoat as any} />{' '}
                <Highlight attribute="manufacturer.name" hit={itemBoat as any} />{' '}
                <Highlight attribute="model" hit={itemBoat as any} />
              </Title>
              <div className={styles.listItemStatistics}>
                <div>
                  <EyeOutlined /> {itemBoat.views_count || 0}
                </div>
                <div>
                  <HeartFilled /> {itemBoat.favorites_count || 0}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  if (indexName === 'manufacturer') {
    const itemMaker = item as ManufacturerType;

    if (!itemMaker.name) {
      return null;
    }

    return (
      <div className={styles.searchResultItemWrapper}>
        <Link href={`/maker/${itemMaker.slug}`} title={itemMaker.name}>
          <div className={styles.listItemWrapper}>
            <div className={styles.listItemImage}>
              {!!itemMaker?.logo?.url ? (
                <Image
                  alt={itemMaker.logo.alternativeText || itemMaker.name}
                  src={itemMaker.logo.url}
                  width={115}
                  height={65}
                />
              ) : (
                <div className={styles.noListItemImagePlaceholder}>
                  <span>No image</span>
                </div>
              )}
            </div>
            <div className={styles.listItemText}>
              <Title level={3}>
                <Highlight attribute="name" hit={itemMaker as any} />
              </Title>
              <div className={styles.listItemStatistics}>
                <div>
                  <span>{itemMaker.publicBoatsCount || 0} </span>
                  <span>boats</span>
                </div>
                <div>
                  <span>Maker</span>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
};
export { SearchResultItem };
