import { SearchOutlined } from '@ant-design/icons';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { useConfig } from 'providers';
import { FC } from 'react';
import { Configure, Index, InstantSearch, SearchBox } from 'react-instantsearch';
import styles from './CoverSearchbox.module.scss';
import { CoverSearchboxProps } from './CoverSearchbox.props';
import { HitsPanel } from './HitsPanel';
import { SearchStatistics } from './SearchStatistics';

const CoverSearchbox: FC<CoverSearchboxProps> = () => {
  const { meiliSearchApiKey, meiliSearchHostUrl } = useConfig();
  const meiliSearchClient = instantMeiliSearch(meiliSearchHostUrl, meiliSearchApiKey, {
    keepZeroFacets: true,
  });

  return (
    <div className={styles.coverSearchboxWrapper}>
      <div className={styles.coverSearchbox}>
        <InstantSearch searchClient={meiliSearchClient}>
          <div className={styles.searchBox}>
            <SearchBox placeholder="Search boat or makers..." submitIconComponent={() => null} />
            <SearchOutlined size={40} />
          </div>
          <div className={styles.hitsListWrapper}>
            <Index indexName="manufacturer">
              <HitsPanel />
              <Configure index="manufacturer" key={'id'} hitsPerPage={1} />
            </Index>
            <Index indexName="boat">
              <HitsPanel showOnEmptyQuery />
              <Configure index="boat" key={'id'} />
            </Index>
          </div>
          <SearchStatistics />
        </InstantSearch>
      </div>
    </div>
  );
};

export { CoverSearchbox };
