globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"a35305598214d2afbbf8fa87f26d233eca662ab4"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { browserEnv } from './utils/browserEnv';

if (browserEnv.NEXT_PUBLIC_SENTRY_ENABLED) {
  Sentry.init({
    dsn:
      process.env.NEXT_PUBLIC_SENTRY_DNS || 'https://75df8161a4124a53b5c65ce615b2eb20@o285994.ingest.sentry.io/6261792',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
